import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div
      className={"wrapper"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        textAlign: "center",
        margin: 0,
        padding: "20px",
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p>You will be redirected to the Home page in 3 seconds...</p>
    </div>
  );
};

export default NotFound;
