import "./App.css";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Capabilities from "./Pages/Capabilities";
import Quality from "./Pages/Quality";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Footer from "./Pages/Footer";
import NotFound from "./Pages/NotFound";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Nav from "./Pages/Nav";
import React from "react";

function App() {
  return (
    <Router>
      <div className={"flex-wrapper"}>
        <Nav />
        <div className={"content-wrapper"}>
          <Outlet />
          <Routes>
            <Route exact path="/" exect element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="capabilities" element={<Capabilities />} />
            <Route path="quality" element={<Quality />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
