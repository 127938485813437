// import { ContactMap } from 'ContactMap'; // Adjust the path accordingly
import React from 'react';
import {
    Popup,
    MapContainer,
    TileLayer,
    Marker
} from 'react-leaflet'
import {LeafletMap} from "./ContactMap";
const position = [39.158030, -76.680020]
export default function  Contact () {
    return <>
        <div className={"content-wrapper"}>
            <div className={"content"}>
                <h1>
                    Contact
                </h1>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{flex: '1'}}>
                        <p style={{verticalAlign: 'text-top'}}>
                            Contrax Technologies Inc. welcomes your questions, comments and suggestions. A sales or
                            customer service
                            representative will be in touch within the next 24 hours. If you have an immediate question,
                            please
                            call us toll free. If you are unable to access the form, please send us an email.
                            <br/>
                            <br/>
                            <span style={{fontSize: 'smaller'}}>
                                Address: 7509 Connelly Drive, Hanover, Maryland 21076
                                <br/>
                                Phone: <a href={`tel:4107606611`}>(410) 760-6611</a>
                                <br/>
                                Email: <a href={'mailto:contrax@contrax-tech.com'}>contrax@contrax-tech.com</a>
                            </span>

                        </p>
                    </div>


                    <div style={{width: '50%', height: '400px', padding: '50px'}}>
                        <MapContainer center={position} zoom={15} minZoom={13} scrollWheelZoom={false}
                                      maxBounds={[position, position]} attributionControl={false}
                                      style={{width: '100%', height: '100%'}}>

                            <TileLayer
                                attribution='&copy; Google contributors'
                                url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />
                            <Marker position={position} radius={20} color="red" fillOpacity={0.5}>
                                <Popup>
                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=Contrax Technologies Inc, 7509 Connelley Dr # C, Hanover, MD 21076`}>Get
                                        Directions</a>
                                </Popup>
                            </Marker>

                            <div className="leaflet-bottom leaflet-center"
                                 style={{fontSize: "small", background: 'white', padding: '10px', opacity: '80%'}}>
                                Click on marker for directions.
                            </div>
                        </MapContainer>
                    </div>
                </div>

                {/* Possibly implement form input here */}
                <div>

                </div>
            </div>
        </div>
    </>
}